<div class="w-full h-full p-2 flex flex-col gap-4">
    <div class="w-full flex flex-col gap-2 bg-white p-2 shadow-md">
        <div class="text-lg">Infringement regeneration for subcompanies of the current selected company</div>
        <hr>
        <div>
            <p>Regenerate infringements for subcompanies</p>
            <button *ngIf="!loading" class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                (click)="regenerateInfringements()">Regenerate</button>
            <div class="animate-spin w-min m-6" *ngIf="loading">Loading..</div>
        </div>
        <div>
            <p>
                Status
            </p>
            <div *ngIf="currentTaskInQueue>0" class="flex flex-col ">
                <div>Regenerating.. ( {{currentTaskInQueue}} drivers remaining ) </div>
                <button class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                    (click)="cancelRegenerateInfringements()">Cancel</button>
            </div>
            <div *ngIf="currentTaskInQueue==0">
                No active regeneration
            </div>
        </div>
    </div>


    <div class="w-full flex flex-col gap-2 bg-white p-2 shadow-md">
        <div class="text-lg">Calculate billing</div>
        <hr>
        <div class="flex flex-row gap-2">
            <div class="flex flex-row gap-2">
                <div>Year</div>
                <input type="number" [(ngModel)]="billingYear" class="border border-gray-300 p-1 rounded-md">
            </div>
            <div class="flex flex-row gap-2">
                <div>Quarter</div>
                <input type="number" [(ngModel)]="billingQuarter" class="border border-gray-300 p-1 rounded-md">
            </div>
            <button class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                (click)="calculateBilling()">Calculate</button>
        </div>

    </div>

    <div class="w-full flex flex-col gap-2 bg-white p-2 shadow-md">
        <div class="text-lg">Reload data for file </div>
        <hr>
        <div class="flex flex-col gap-2">
            <div class="flex flex-row gap-2">
                <div>Hash</div>
                <input [(ngModel)]="reloadFileHash" class="border border-gray-300 p-1 rounded-md">
            </div>
            <button class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                (click)="reloadDataForFile()">Reload</button>
        </div>

    </div>

    <div class="w-full flex flex-col gap-2 bg-white p-2 shadow-md">
        <div class="text-lg">Reactivate not paying client</div>
        <hr>
        <div class="flex flex-col gap-2">
            <div class="flex flex-row gap-2">
                <div>Client Id</div>
                <input [(ngModel)]="reactivateNotPayingClientId" class="border border-gray-300 p-1 rounded-md">
            </div>
            <button class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                (click)="reactivateNotPayingClient()">Reactivate</button>
        </div>
    </div>

    <div class="w-full flex flex-col gap-2 bg-white p-2 shadow-md">
        <div class="text-lg">Resend activation email</div>
        <hr>
        <div class="flex flex-col gap-2">
            <div class="flex flex-row gap-2">
                <div>Username</div>
                <input [(ngModel)]="resendActivationEmailUname" class="border border-gray-300 p-1 rounded-md">
            </div>
            <div class="flex flex-row gap-2">
                <div>EmailAddress</div>
                <input [(ngModel)]="resendActivationEmailEmail" class="border border-gray-300 p-1 rounded-md">
            </div>
            <button class="bg-tachosafe-500 px-2 py-1 text-white text-center"
                (click)="resendActivationEmail()">Resend</button>
        </div>
    </div>
</div>