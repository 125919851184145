<div class="w-full h-full p-2">
    <p-toast></p-toast>
    <div class="bg-white rounded-md p-2 flex flex-col">
        <h3>{{'Device registration'|translate}}</h3>
        <hr class="mb-4">
        <div class="w-full flex flex-col gap-4">
            <div>{{'Register {deviceId} to {companyName}'|translate|replaceValues: {deviceId:imei,
                companyName:companyName} }}</div>
            <button 
                [disabled]="buttonDisabled"
                (click)="register()"
            class="text-white px-2 py-1 font-bold bg-tachosafe-500 disabled:bg-gray-400 rounded-md">
                {{ 'Register'|translate }}
            </button>
        </div>
    </div>

</div>