import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';
import { DevicesService } from 'app/services/devices/devices.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-connect-register',
  templateUrl: './connect-register.component.html',
  styleUrls: ['./connect-register.component.css']
})
export class ConnectRegisterComponent implements OnInit {

  imei: string = '';
  pin: string = '';
  companyName: string = '';
  buttonDisabled: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly deviceService: DevicesService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      this.imei = params['i'];
      this.pin = params['p'];
    });
  }



  ngOnInit(): void {
    this.authService.currentCompanyId.subscribe(async (companyId) => {
      if (companyId) {
        this.companyName = (await this.authService.getCompanyName(companyId.toString()).toPromise()).companyName;
      }
    })
  }

  register() {
    this.buttonDisabled = true;
    this.deviceService.registerDevice(this.imei, this.pin, this.authService.currentCompanyIdValue()).subscribe((resp) => {
      if (resp.result === 'Success') {
        this.messageService.add({  severity: 'success', summary: this.translate.instant('Successfully registered'), detail: this.translate.instant('Device added to your company') });
        setTimeout(() => {
          this.router.navigate(['/devices']);
        }, 2000);
      } else {
        this.messageService.add({ severity: 'error', summary: this.translate.instant('Failed'), detail: this.translate.instant(resp.msg) });
      }
      this.buttonDisabled = false;
    })
  }

}
