import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_URL} from '../../helpers/globals';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';

export class RegisterResponse {
    result: string;
    msg: string;
}

export interface Device {
    id: number;
    client: number;
    imei: string;
    pin: string;
    registry_date: number;
    production_date: number;
    deleted: number;
    destroyed: string;
    changedNick: string;
    settings: SettingsDevice;
}

export interface SettingsDevice {
    imei: string;
    dsDownload: number;
    nick: string;
    nrOfDays: number;
    uploadSettings: 'all'|'auto' | 'manual';
}

export interface Devices {
    result: Device[];
}

export interface DevicesDeleteResponse {
    result: string;
}

export class SendResponse {
    message: string;
}

export class GetSettingsResponse {
    dsDownload: number;
    imei: string;
    nick: string;
    nrOfDays: number;
    uploadSettings: 'all'|'auto' | 'manual';
}

@Injectable()
export class DevicesService {

    constructor(private http: HttpClient) {
    }


    registerDevice(imei: string, pin: string, clientId: number): Observable<RegisterResponse> {
        return this.http.post<RegisterResponse>(API_URL + 'device/register', {
            imei: imei,
            pin: pin,
            clientId: clientId
        }, {withCredentials: true}).pipe(share());
    }

    getDevices(companyId: number): Observable<Device[]> {
        const params: HttpParams = new HttpParams()
            .append('clientId', '' + companyId);

        return this.http.get<Devices>(API_URL + 'device/list',
            {params: params, withCredentials: true})
            .pipe(map((response) => response.result))
            .pipe(map((data) => {
                data.forEach(d=>{
                    const response = d.settings;
                    if (response.nrOfDays === undefined || response.nrOfDays === null) {
                        response.uploadSettings = 'auto';
                    }
                    if(response.nrOfDays === 0) {
                        response.uploadSettings = 'all';
                    }
                    if(response.nrOfDays > 0) {
                        response.uploadSettings = 'manual';
                    }
                })
                
                return data;
            }));
    }

    deleteDevice(id: number): Observable<DevicesDeleteResponse> {
        return this.http
            .get<DevicesDeleteResponse>(API_URL + 'device/delete/' + id,
                {withCredentials: true}
            );
    }

    sendDeviceSettings(imei: string, dsDownload: number): Observable<SendResponse> {
        return this.http
            .post<SendResponse>(API_URL + 'device/postSettings', {
                imei: imei,
                dsDownload: dsDownload
            }, {withCredentials: true}).pipe(share());
    }

    sendDeviceSettingsNickName(imei: string, nick: string): Observable<SendResponse> {
        return this.http
            .post<SendResponse>(API_URL + 'device/postSettings', {
                imei: imei,
                nick: nick
            }, {withCredentials: true}).pipe(share());
    }

    sendDeviceSettingsNumberOfDays(imei: string, nrOfDays: null|number): Observable<SendResponse> {
        return this.http
            .post<SendResponse>(API_URL + 'device/postSettings', {
                imei: imei,
                nrOfDays:nrOfDays
            }, {withCredentials: true}).pipe(share());
    }

    getDeviceSettings(imei: string): Observable<GetSettingsResponse> {
        const params: HttpParams = new HttpParams()
            .append('imei', '' + imei);

        return this.http.get<GetSettingsResponse>(API_URL + 'device/getSettings',
            {params: params, withCredentials: true}).pipe(share())
            .pipe(map((response) => {
                if  (response.nrOfDays === undefined || response.nrOfDays === null) {
                    response.uploadSettings = 'auto';
                }
                if(response.nrOfDays === 0) {
                    response.uploadSettings = 'all';
                }
                if(response.nrOfDays > 0) {
                    response.uploadSettings = 'manual';
                }
                return response;
            }));
    }
}
