import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // console.log('canActivate asr');
        const url = state.url;
        return this.canAct(url) ;
    }

    async canAct(url:string):Promise<boolean> {
        // console.log('canAct asr');
        try{
            if(localStorage.getItem('loggedInUser') === null) {
                this.authService.setRedirectUrl(url);
            }
            const isLoggedIn = (await this.authService.asr()) !== null;
            return isLoggedIn;

        }catch (error) {
            this.authService.setRedirectUrl(url);
            return false;
        }
        
    }


}
